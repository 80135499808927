<template>
  <div class="services">
    <HeadView :classA="classANum" headBg="1"></HeadView>
    <div class="main">
      <div class="bannerWrap">
        <img
          alt=""
          class="newsBanner"
          src="@/assets/images/services/servicesBanner.png"
        />
        <img
          alt=""
          class="newsIcon"
          src="@/assets/images/services/servicesIcon.png"
          width="197"
        />
      </div>
      <div class="outer">
        <div class="left">
          <ul id="headUl">
            <li class="active">客户服务</li>
          </ul>
        </div>
        <div class="right">
          <p class="textWrap">
            致医健康对售出产品执行全国统一的售后服务政策，客户可通过拨打热线电话获得售后服务，或关注“致医云诊室”公众号获得在线答疑及视频远程协助服务，也可通过查询致医健康官方代理获得帮助。
          </p>
          <dl id="servicesId" class="servicesWrap">
            <dt>在线客服体系</dt>
            <dd>
              <div class="listWrap">
                <div class="listText">
                  <h4>热线电话</h4>
                  <p>服务时间：7*24h，全年无休</p>
                  <p>联系方式：400-620-9299</p>
                </div>
                <img alt="" src="@/assets/images/services/scon1.png" />
              </div>
              <div class="listWrap">
                <div class="listText">
                  <h4>视频客服</h4>
                  <p>服务时间：周一至周五 9:00-18:00</p>
                  <p>
                    联系方式一：微信扫码致医云诊室公众号，点击菜单栏“视频客服”，长按识别图中小程序即可接入
                  </p>
                  <p>联系方式二：微信扫描右方小程序码，直接接入视频客服</p>
                </div>
                <img alt="" src="@/assets/images/services/scon2.png" />
              </div>
              <div class="listWrap">
                <div class="listText">
                  <h4>公众号在线留言</h4>
                  <p>服务时间：周一至周日 9:00-18:00</p>
                  <p>
                    联系方式：微信扫码进入“致医云诊室”公众号，点击对话栏输入想要咨询问题即可获得回复。
                  </p>
                </div>
                <img alt="" src="@/assets/images/services/scon3.png" />
              </div>
            </dd>
          </dl>
          <dl id="officialAgentId" class="officialAgentWrap">
            <dd>
              <div @click="showJoin" class="businessWrap">
                <img alt="" src="@/assets/images/services/bottomBanner.png" />
                <div class="businessText">
                  <p>官方代理火热招募中，期待您的加盟！</p>
                  <span>立即加盟</span>
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <Foot></Foot>
    <JoinBox
      ref="joinBox"
      companyText="企业名称（选填）"
      type="0"
      title="申请加盟官方代理"
    ></JoinBox>
  </div>
</template>
<style scoped>
@import "../assets/style/leftNav.css";
</style>
<style lang="scss" scoped>
.services {
  .select-list {
    position: relative;
  }
  .selects {
    display: flex;
    padding: 0 15px;
    width: 100%;
    height: 48px;
    font-size: 14px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(43, 30, 205, 1);
    text-align: left;
    justify-content: space-between;
    align-items: center;
    outline: none;
    &:after {
      content: url("../assets/images/services/arrow.svg");
      transform: rotate(180deg);
      transition: all 0.5s;
      &:focus {
        outline: none;
        border: 0 none;
      }
    }
  }
  .rotate {
    &:after {
      transform: rotate(0deg);
    }
  }
  .list {
    position: absolute;
    width: 100%;
    z-index: 99999;
    font-size: 14px;
    box-shadow: 0px 5px 10px 0px rgba(41, 26, 204, 0.12);
    background: #fff;
    .item {
      padding: 0 15px;
      height: 48px;
      line-height: 48px;
      &:hover {
        background: rgba(34, 19, 222, 1);
        color: #fff;
      }
    }
  }
  .select {
    width: 160px;
    height: 48px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(43, 30, 205, 1);
    font-size: 14px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;
    .outer {
      .right {
        .textWrap {
          padding: 30px;
          background: #fff;
          box-sizing: border-box;
          text-align: left;
          line-height: 28px;
          font-size: 14px;
          color: #333;
        }
        dl {
          dt {
            font-size: 18px;
            color: #333;
            text-align: left;
            padding: 30px 0 14px 20px;
            font-weight: bold;
          }
          dd {
            .listWrap {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 14px;
              background: #fff;
              .listText {
                padding: 20px 30px;
                flex-grow: 1;
                h4 {
                  font-size: 16px;
                  color: #333;
                  text-align: left;
                  padding-bottom: 10px;
                  font-weight: bold;
                }
                p {
                  font-size: 14px;
                  color: #333;
                  text-align: left;
                  line-height: 22px;
                }
              }

              img {
                width: 100%;
                max-width: 250px;
                // align-self: flex-start;
              }
            }
            .businessWrap {
              margin-top: 30px;
              justify-content: center;
              align-items: center;
              display: flex;
              img {
                position: relative;
                z-index: 1;
                width: 100%;
              }
              .businessText {
                position: absolute;
                z-index: 2;
                p {
                  width: 612px;
                  height: 50px;
                  font-size: 36px;
                  color: rgba(255, 255, 255, 1);
                  line-height: 50px;
                }
                span {
                  display: inline-block;
                  width: 160px;
                  height: 44px;
                  line-height: 44px;
                  background: rgba(255, 255, 255, 1);
                  border-radius: 4px;
                  font-size: 14px;
                  color: #2213de;
                  margin-top: 40px;
                  cursor: pointer;
                }
              }
            }
          }
        }
        dl.officialAgentWrap {
          dt {
            padding-top: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .main {
      width: 90%;
    }
  }
  @media screen and (max-width: 640px) {
    .main .outer .right dl dd .businessWrap .businessText p {
      font-size: 24px;
    }
  }
}
</style>
<script>
import Foot from "@/components/common/Foot";
import HeadView from "@/components/common/Head";
import JoinBox from "@/components/common/JoinBox";
import "../assets/js/leftNav.js";

export default {
  name: "news",
  components: {
    HeadView,
    Foot,
    JoinBox,
  },
  data() {
    return {
      currentCity: "辽宁",
      options: false,
      cities: [
        {
          id: 0,
          text: "辽宁",
        },
        {
          id: 1,
          text: "山东",
        },
        {
          id: 2,
          text: "福建",
        },
        {
          id: 3,
          text: "河北",
        },
        {
          id: 4,
          text: "四川",
        },
        {
          id: 5,
          text: "黑龙江",
        },
        {
          id: 6,
          text: "吉林",
        },
        {
          id: 7,
          text: "新疆",
        },
        {
          id: 8,
          text: "湖北",
        },
        {
          id: 9,
          text: "河南",
        },
        {
          id: 10,
          text: "云南",
        },
      ],
      classANum: "2",
    };
  },
  methods: {
    toggleOption() {
      this.options = !this.options;
    },
    change(city) {
      this.currentCity = city.text;
      this.options = false;
    },
    showJoin() {
      this.$refs.joinBox.isShowDialog = true;
    },
  },
  mounted() {},
};
</script>
